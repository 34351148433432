<template>
  <div class="base-form">
    <el-form
      label-position="left"
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="scoreRules"
      label-width="125px"
      class="demo-ruleForm"
    >
      <el-form-item label="方案名称：" prop="name">
        <div class="ruleName">
          <el-input size="small" v-model="ruleForm.name" placeholder="方案名称" />
        </div>
      </el-form-item>
      <el-form-item :label="ruleKind == 1 ? '关联门店：' : '关联角色 '" prop="correlationIds">
        <div class="opt-shop">
          <div>
            <el-button @click="handleOptRoleClick" size="small" plain
              >{{ ruleKind == 1 ? "选择门店" : "选择角色" }}
            </el-button>
          </div>
          <div class="opt-shopname">
            已选:<template :key="roleitem" v-for="roleitem in currentOptRoleName">
              <el-tag size="small">{{ roleitem }}</el-tag>
            </template>
          </div>
        </div>
      </el-form-item>
      <div style="display: flex;">
        <div>
            <el-form-item class="xiaohao-yeji-form" label="消耗业绩：">
            <el-radio-group v-model="ruleForm.resource">
              <el-radio>计算</el-radio>
              <el-radio>不计算</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="choose_item" v-for="(item,index) in consumeList" :key="index">
            <el-form-item :label="item.label">
              <el-radio-group v-model="item.calculate">
                <el-radio :label="true"></el-radio>
                <el-radio :label="false"></el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
        <div style="margin-left: 150px;">
          <el-form-item class="xiaohao-yeji-form" label="销售业绩：">
            <el-radio-group v-model="ruleForm.resource">
              <el-radio>计算</el-radio>
              <el-radio>不计算</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="choose_item" v-for="(item,index) in saleList" :key="index">
            <el-form-item :label="item.label">
              <el-radio-group v-model="item.calculate">
                <el-radio :label="true"></el-radio>
                <el-radio :label="false"></el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
      </div>
      <el-form-item>
        <div class="save-btn" v-if="!$route.query.isview">
          <el-button @click="handleSaveClick(ruleFormRef)" type="primary">保存</el-button>
        </div>
      </el-form-item>
    </el-form>
    <distributeDialog
      :ruleKind="ruleKind"
      @changeOptShop="changeOptShop"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      :chooseList="checkList"
    ></distributeDialog>
    <!-- 遮罩 -->
    <div v-if="$route.query.isview" class="mask"></div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import distributeDialog from '../../../base-ui/distribute-dialog/distribute-dialog.vue'
import duorenFenpeiDialog from '../../../base-ui/duoren-fenpei-dialog/duoren-fenpei-dialog.vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { getScrollRuleDetail, editAchievementRule } from '@/service/main/score'
import { getUnBindRuleShopList,getUnBindRuleRoleList } from '@/service/main/commission'
import { scoreRules } from '../../../config'
import { throttle } from '@/utils/debounce'


export default defineComponent({
  props: {

  },
  components: {
    distributeDialog,
    duorenFenpeiDialog
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const ruleKind = computed(() => route.query.ruleKind)

    const ruleForm = reactive({
      name:"",
      type:ruleKind.value
    })
    const consumeList = ref([
      {kind:1,label:'单次消费',calculate:true},
      {kind:2,label:'储值卡——本金',calculate:true},
      {kind:3,label:'储值卡——赠金',calculate:true},
      {kind:4,label:'折扣卡——购买',calculate:true},
      {kind:5,label:'有限次卡——购买',calculate:true},
      {kind:6,label:'有限次卡——赠送',calculate:true},
      {kind:7,label:'兑换券',calculate:true},
      {kind:8,label:'现金券',calculate:true},
    ])
    const saleList = ref([
      {kind:5,label:'储值卡',calculate:true},
      {kind:6,label:'折扣卡',calculate:true},
      {kind:3,label:'有限次卡',calculate:true},
      {kind:7,label:'兑换券',calculate:true},
      {kind:8,label:'现金券',calculate:true},
    ])

    const dialogVisible = ref(false)
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
    }

    const handleOptRoleClick = () => {
      dialogVisible.value = true
    }

    const yejiForm = ref([
      { value: '' },
      { value: '' },
    ])

    const yejiForm2 = ref([
      { value: '' },
      { value: '' },
    ])

    const yejiFormChange = (value) => {
      // console.log(value);
      yejiForm.value = value
    }

    const yejiFormChange2 = (value) => {
      // console.log(value);
      yejiForm2.value = value
    }

    const isShowPnum2 = ref(false)
    const isShowPnum = ref(false)
    const changeYejiSave = () => {
      isShowPnum.value = false
    }
    const changeYejiSave2 = () => {
      isShowPnum2.value = false
    }
    const handleShoudongSettingClick = () => {
      isShowPnum.value = true
    }
    const handleShoudongSettingClick2 = () => {
      isShowPnum2.value = true
    }


    const currentOptRoleName = ref([])
    const changeOptShop = (obj) => {
      ruleForm.correlationIds = obj.ids;
      currentOptRoleName.value = obj.optNameArr;
    }

    const handleSharetypeChange = (v) => {
      if (v == 1) {
        isShowPnum.value = false
      }

    }

    const handleSharetypeChange2 = (v) => {
      if (v == 1) {
        isShowPnum2.value = false
      }
    }

    const ruleFormRef = ref()

    const handleSaveClick = throttle (async (formEl = undefined) => {
      if (!formEl) return
      ruleForm.consumeRuleList=consumeList.value.map(item=>{
        return {
          kind:item.kind,
          calculate:item.calculate
        }
      })
      ruleForm.saleRuleList=saleList.value.map(item=>{
        return {
          kind:item.kind,
          calculate:item.calculate
        }
      })
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          const res = await editAchievementRule({...ruleForm,id:route.query.ruleId})
          if (res.code == 0) {
            ElMessage({
              message: '操作成功!',
              type: 'success',
            })
            router.push({
              path: '/main/score/scoreRuleList'
            })
          }
          // console.log('submit!')
        } else {
          ElMessage({
            message: '参数填写不完整！',
            type: 'warning',
          })
          // console.log('error submit!', fields)
        }
      })
    },1000)
    const checkList=ref([])
    const initPage = async () => {
      const res = await getScrollRuleDetail(route.query.ruleId)
      const {name,consumeRuleList,saleRuleList,correlationIds}=res.data
      ruleForm.name=name
      for (let i = 0; i < consumeRuleList.length; i++) {
        for (let j = 0; j < consumeList.value.length; j++) {
          if (consumeList.value[j].kind==consumeRuleList[i].kind) {
            consumeList.value[j].calculate=consumeRuleList[i].calculate
          }
        }
      }
      if (saleRuleList.length) {
        for (let i = 0; i < saleRuleList.length; i++) {
          for (let j = 0; j < saleList.value.length; j++) {
            if (saleList.value[j].kind==saleRuleList[i].kind) {
              saleList.value[j].calculate=saleRuleList[i].calculate
            }
          }
        }
      }
      
      let shopList;
      if (ruleKind.value == 1) {
        shopList = await getUnBindRuleShopList(route.query.ruleId,1)
        currentOptRoleName.value = arrHasSameValue(shopList.data,correlationIds);
      } else {
        shopList = await getUnBindRuleRoleList(route.query.ruleId,2)
        currentOptRoleName.value = arrHasSameValue1(shopList.data,correlationIds);
      }
      checkList.value=correlationIds
      ruleForm.correlationIds = correlationIds
    }
    const arrHasSameValue = (arr1, arr2) => {
      console.log(arr1, arr2);
        let arr = [];
        for (let i = 0; i < arr1.length; i++) {
            for (let j = 0; j < arr2.length; j++) {
                if (arr1[i].shopId == arr2[j]) {
                    arr.push(arr1[i].shopName)
                }
            }
        }
        return arr;
    }
    const arrHasSameValue1 = (arr1, arr2) => {
        let arr = [];
        for (let i = 0; i < arr1.length; i++) {
            for (let j = 0; j < arr2.length; j++) {
                if (arr1[i].id == arr2[j]) {
                    arr.push(arr1[i].name)
                }
            }
        }
        return arr;
    }
    initPage()
    return {
      scoreRules,
      ruleFormRef,
      handleSaveClick,
      handleShoudongSettingClick,
      handleShoudongSettingClick2,
      changeYejiSave,
      changeYejiSave2,
      isShowPnum,
      isShowPnum2,
      handleSharetypeChange,
      handleSharetypeChange2,
      yejiForm,
      yejiForm2,
      yejiFormChange,
      yejiFormChange2,
      currentOptRoleName,
      handleOptRoleClick,
      changeOptShop,
      ruleForm,
      ruleKind,
      dialogVisible,
      changeDialogVisible,
      consumeList,
      saleList,
      arrHasSameValue,
      checkList
    }

  }
})
</script>

<style scoped lang="less">
.base-form {
  min-height: 800px;
  padding-bottom: 50px;
  position: relative;
  .mask {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }

  .opt-shop {
    display: flex;

    .opt-shopname {
      margin-left: 12px;
      font-size: 12px;

      &:deep(.el-tag) {
        margin-left: 10px;
      }
    }
  }

  &:deep(.el-form-item__error) {
    margin-bottom: 10px;
  }

  &:deep(.xiaohao-yeji-form) {
    .el-form-item__content {
      line-height: normal !important;
    }
    .el-radio__input{
      display:none
    }
    .el-radio-group{
      margin-left: 50px;
    }
  }
  &:deep(.choose_item){
    margin-left: 50px;
    .el-radio__label{
      display: none;
    }
  }
  &:deep(.el-radio__input){
    margin-left: 15px;
    margin-right: 10px;
  }

  .duoren-fenpei {
    padding: 0 10px;

    .renshu-value {
      display: flex;
      width: 455px;
      padding: 0 10px;

      & > div {
        flex: 1;
        line-height: 36px;
        font-size: 12px;
        color: rgba(80, 80, 80, 1);
      }
    }

    .pnum-title {
      display: flex;
      width: 455px;
      padding: 0 10px;
      background-color: rgba(231, 231, 231, 1);

      & > div {
        flex: 1;
        line-height: 36px;
        font-size: 12px;
        color: rgba(80, 80, 80, 1);
      }
    }
  }

  .xiaohao-yeji {
    display: flex;
    flex-wrap: wrap;

    .opt-checkbox {
      border-radius: 5px;
      font-size: 14px;
      width: 240px;
      padding: 16px 14px;
      min-height: 146px;
      border: 1px solid rgb(204, 204, 204, 1);
      margin-bottom: 12px;
      margin-right: 12px;

      .opt-title {
        margin-bottom: 11px;
      }

      .opt-title-btottom {
        margin: 16px 0;
      }
    }
  }

  .ruleName {
    &:deep(.el-input) {
      max-width: 260px;
    }
  }

  .error-tips {
    color: #f56c6c;
    margin-top: 10px;
    font-size: 12px;
  }

  .fangan-name {
    &:deep(.el-input) {
      max-width: 262px;
    }
  }

  .yeji-form-center {
    align-items: center;
  }

  .yeji-form {
    display: flex;
    margin-bottom: 27px;
    font-size: 14px;

    .yj-label {
      width: 120px;
      font-weight: bold;
      color: rgba(80, 80, 80, 1);
      position: relative;
      padding-left: 6px;

      span {
        color: #f56c6c;
        font-size: 12px;
        position: absolute;
        left: 0;
      }
    }

    .duoren-fenpei {
      padding: 0 10px;

      .renshu-value {
        display: flex;
        width: 455px;
        padding: 0 10px;

        & > div {
          flex: 1;
          line-height: 36px;
          font-size: 12px;
          color: rgba(80, 80, 80, 1);
        }
      }

      .pnum-title {
        display: flex;
        width: 455px;
        padding: 0 10px;
        background-color: rgba(231, 231, 231, 1);

        & > div {
          flex: 1;
          line-height: 36px;
          font-size: 12px;
          color: rgba(80, 80, 80, 1);
        }
      }
    }

    .yj-value {
      flex: 1;
      position: relative;

      .dr-fenpei {
        position: absolute;
      }
    }

    .save-from {
      display: flex;
      align-items: center;
      margin-top: 64px;

      &:deep(.el-button) {
        width: 213px;
        height: 48px;
      }

      .tips {
        color: rgba(153, 153, 153, 1);
        font-size: 14px;
        margin-left: 19px;
      }
    }
  }
  .save-btn{
    margin:30px 0 0 250px;
  }

}
</style>
