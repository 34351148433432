<template>
  <div class="add-rule">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="main-content">
      <baseForm></baseForm>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import baseForm from './cpns/base-form/base-form.vue'
export default defineComponent({
  props: {

  },
  components: {
    baseForm,
    Breadcrumb
  },
  setup() {


    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.add-rule {
  .main-content{
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>




