export const breadcrumbList = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '业绩规则',
    path: '/main/score/scoreRuleList'
  },
  {
    name: '规则详情',
    path: ''
  }
]

